* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* Z Indices */
  --z-index-modal: 1000;
  --z-index-dropdown: 900;
  --z-index-tooltip: 800;
  --z-index-forefront: 1;
  --z-index-backdrop: -1;
  --radius: 12px;
  --radius-md: 8px;
  --radius-xl: 12px;
  --border-secondary: #eaecf0;
  --bg-primary: #fff;
  --shadow-xs: 0px 1px 2px 0px rgb(16 24 40 / 5%);
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 6px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-3xl: 24px;
  --spacing-7xl: 64px;
  --spacing-2xl: 20px;
  --spacing-none: 0;

  /* Fonts */
  --xl-semibold: 600 30px/38px var(--primary-font);
  --xl-medium: 500 30px/38px var(--primary-font);
  --xl-regular: 400 30px/38px var(--primary-font);
  --lg-semibold: 600 18px/26px var(--primary-font);
  --lg-medium: 500 18px/26px var(--primary-font);
  --lg-regular: 400 18px/26px var(--primary-font);
  --md-semibold: 600 16px/24px var(--primary-font);
  --md-medium: 500 16px/24px var(--primary-font);
  --md-regular: 400 16px/24px var(--primary-font);
  --sm-semibold: 600 14px/20px var(--primary-font);
  --sm-medium: 500 14px/20px var(--primary-font);
  --sm-regular: 400 14px/20px var(--primary-font);
  --xs-medium: 500 12px/18px var(--primary-font);
  --xs-regular: 400 12px/18px var(--primary-font);

  /* Colors */
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #16191d;
  --gray-11: #0d0f12;
  --gray-12: #030507;
  --blue: #1693ee;
  --red: #fc1b43;
  --red-1: #fef3f2;
  --red-2: #fecdca;
  --red-3: #b42318;
  --green-1: #ecfdf3;
  --green-2: #abefc6;
  --green-3: #067647;
  --orange-1: #fef6ee;
  --orange-2: #f9dbaf;
  --orange-3: #b93815;
  --purple-1: #f9f5ff;
  --purple-2: #e9d7fe;
  --purple-3: #6941c6;
  --blue-0: #f2f4f7;
  --blue-1: #eff8ff;
  --blue-2: #b2ddff;
  --blue-3: #175cd3;
  --indigo-1: #eef4ff;
  --indigo-2: #c7d7fe;
  --indigo-3: #3538cd;
  --pink-1: #fdf2fa;
  --pink: 2: #fcceee;
  --pink-3: #c11574;
  --buttons-primary: #7f56d9;
  --button-primary-fg: #fff;
  --text-primary-900: #101828;
  --text-secondary-700: #344054;
  --text-tertiary-600: #475467;
  --border-primary: #d0d5dd;
  --text-secondary: #182230;
  --text-primary-600: #101828;
  --text-placeholder: #667085;
  --bg-secondary-hover: #182230;
  --bg-primary: #fff;
  --utility-gray-50: #f9fafb;
  --utility-gray-200: #eaecf0;
  --utility-success-50: #ecfdf3;
  --utility-success-200: #abefc6;
  --utility-orange-50: #fef6ee;
  --utility-orange-200: #f9dbaf;
  --utility-success-700: #067647;
  --utility-orange-700: #b93815;
  --filter-outline: #d6bbfb;
}

html,
body {
  min-height: 100vh;
  color: var(--text-1);
  background-color: var(--bg-primary);
  scroll-behavior: smooth;
}
