* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* Z Indices */
  --z-index-modal: 1000;
  --z-index-dropdown: 900;
  --z-index-tooltip: 800;
  --z-index-forefront: 1;
  --z-index-backdrop: -1;
  --radius: 12px;
  --radius-md: 8px;
  --radius-xl: 12px;
  --border-secondary: #eaecf0;
  --bg-primary: #fff;
  --shadow-xs: 0px 1px 2px 0px rgb(16 24 40 / 5%);
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 6px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-3xl: 24px;
  --spacing-7xl: 64px;
  --spacing-2xl: 20px;
  --spacing-none: 0;

  /* Fonts */
  --xl-semibold: 600 30px/38px var(--primary-font);
  --xl-medium: 500 30px/38px var(--primary-font);
  --xl-regular: 400 30px/38px var(--primary-font);
  --lg-semibold: 600 18px/26px var(--primary-font);
  --lg-medium: 500 18px/26px var(--primary-font);
  --lg-regular: 400 18px/26px var(--primary-font);
  --md-semibold: 600 16px/24px var(--primary-font);
  --md-medium: 500 16px/24px var(--primary-font);
  --md-regular: 400 16px/24px var(--primary-font);
  --sm-semibold: 600 14px/20px var(--primary-font);
  --sm-medium: 500 14px/20px var(--primary-font);
  --sm-regular: 400 14px/20px var(--primary-font);
  --xs-medium: 500 12px/18px var(--primary-font);
  --xs-regular: 400 12px/18px var(--primary-font);

  /* Colors */
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #16191d;
  --gray-11: #0d0f12;
  --gray-12: #030507;
  --blue: #1693ee;
  --red: #fc1b43;
  --red-1: #fef3f2;
  --red-2: #fecdca;
  --red-3: #b42318;
  --green-1: #ecfdf3;
  --green-2: #abefc6;
  --green-3: #067647;
  --orange-1: #fef6ee;
  --orange-2: #f9dbaf;
  --orange-3: #b93815;
  --purple-1: #f9f5ff;
  --purple-2: #e9d7fe;
  --purple-3: #6941c6;
  --blue-0: #f2f4f7;
  --blue-1: #eff8ff;
  --blue-2: #b2ddff;
  --blue-3: #175cd3;
  --indigo-1: #eef4ff;
  --indigo-2: #c7d7fe;
  --indigo-3: #3538cd;
  --pink-1: #fdf2fa;
  --pink: 2: #fcceee;
  --pink-3: #c11574;
  --buttons-primary: #7f56d9;
  --button-primary-fg: #fff;
  --text-primary-900: #101828;
  --text-secondary-700: #344054;
  --text-tertiary-600: #475467;
  --border-primary: #d0d5dd;
  --text-secondary: #182230;
  --text-primary-600: #101828;
  --text-placeholder: #667085;
  --bg-secondary-hover: #182230;
  --bg-primary: #fff;
  --utility-gray-50: #f9fafb;
  --utility-gray-200: #eaecf0;
  --utility-success-50: #ecfdf3;
  --utility-success-200: #abefc6;
  --utility-orange-50: #fef6ee;
  --utility-orange-200: #f9dbaf;
  --utility-success-700: #067647;
  --utility-orange-700: #b93815;
  --filter-outline: #d6bbfb;
}

html,
body {
  min-height: 100vh;
  color: var(--text-1);
  background-color: var(--bg-primary);
  scroll-behavior: smooth;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_aaf875';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_aaf875';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_aaf875 {font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875';font-style: normal
}.__variable_aaf875 {--primary-font: '__Inter_aaf875', '__Inter_Fallback_aaf875'
}

